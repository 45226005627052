import TYPES from '@/types';
import {
  curpFormat,
  percentageFormat,
  requiredRule,
} from '@/vue-app/utils/form-rules';

// Application
import { GetRelationshipsQueryService } from '@/modules/onboarding/catalogs/relationship/application/queries';

// Domain
import { RelationshipEntity }
  from '@/modules/onboarding/catalogs/relationship/domain/entities/relationship-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class BeneficiariesInCaseOfDeathFormViewModel {
  @Inject(TYPES.GET_RELATIONSHIPS_QUERY_SERVICE)
  readonly get_relationships_query_service!: GetRelationshipsQueryService;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  rules = {
    required: [requiredRule],
    curp: [requiredRule, curpFormat],
    percentage: [percentageFormat],
  };

  relationships: Array<RelationshipEntity> = []

  getFormattedPercent(percentage: string) {
    return percentage ? `${percentage}%` : '0%';
  }

  showRemoveBtn(index: number) {
    return index > 0;
  }

  initialize = async () => {
    await this.loadRelationships();
  }

  loadRelationships = async () => {
    try {
      this.relationships = await this.get_relationships_query_service.execute();
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar el catálogo de parentescos');
    }
  }
}
