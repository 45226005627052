































































































import {
  Component, Vue, PropSync, Prop,
} from 'vue-property-decorator';

import Card from '../Card.vue';
import CustomAutocomplete from '@/vue-app/components/onboarding/CustomAutocomplete.vue';
import BeneficiariesInCaseOfDeathFormViewModel
  from '@/vue-app/view-models/components/on-boarding/beneficiaries/beneficiaries-in-case-of-death-form-view-model';

// Domain
import { BeneficiaryInCaseOfDeathEntity }
  from '@/modules/onboarding/beneficiary/domain/entities/beneficiary-in-case-of-death-entity';

@Component({
  name: 'BeneficiariesInCaseOfDeathForm',
  components: { CustomAutocomplete, Card },
})
export default class BeneficiariesInCaseOfDeathForm extends Vue {
  @PropSync('beneficiary')
  synced_beneficiary!: BeneficiaryInCaseOfDeathEntity;

  @Prop(Number) index!: number;

  beneficiaries_form_view_model = Vue.observable(new BeneficiariesInCaseOfDeathFormViewModel());

  mounted() {
    this.beneficiaries_form_view_model.initialize();
  }

  removeBeneficiariesInCaseOfDeath() {
    this.$emit('removeBeneficiariesInCaseOfDeath', this.index);
  }

  curpToUpperCase() {
    this.synced_beneficiary.curp = this.synced_beneficiary.curp.toUpperCase();
  }
}
